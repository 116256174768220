// services/documentLinksService.ts

import { collection, addDoc, query, where, getDocs, orderBy, Timestamp } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { DocumentLink } from '../models/DocumentLink';

export class DocumentLinksService {
  private db = getFirestore();
  private collectionName = 'documentLinks';

  async saveDocumentLink(data: Omit<DocumentLink, 'id' | 'createdAt'>) {
    try {
      const docRef = await addDoc(collection(this.db, this.collectionName), {
        ...data,
        createdAt: Timestamp.now()
      });
      return docRef.id;
    } catch (error) {
      console.error('Error saving document link:', error);
      throw error;
    }
  }

  async getUserDocumentLinks(userId: string) {
    try {
        console.log('Fetching documents for userId:', userId);
        console.log('Using collection:', this.collectionName);
      const q = query(
        collection(this.db, this.collectionName),
        where('userId', '==', userId)
      );
      console.log('Query constructed:', q);

      const querySnapshot = await getDocs(q);
      console.log('Query snapshot:', querySnapshot);
      console.log('Number of documents:', querySnapshot.size);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate()
      })) as DocumentLink[];
    } catch (error) {
      console.error('Error getting user document links:', error);
      throw error;
    }
  }
}