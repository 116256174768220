import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, orderBy, getFirestore } from 'firebase/firestore';
import { Card } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './DocumentLinksList.css';

interface DocumentLink {
  id: string;
  userId: string;
  documentUrl: string;
  topic: string;
  cardId: string;
  createdAt: Date;
  title?: string;
}

interface GroupedDocuments {
  [topic: string]: DocumentLink[];
}

const DocumentLinksList = () => {
  const [documentLinks, setDocumentLinks] = useState<GroupedDocuments>({});
  const [loading, setLoading] = useState(true);
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const [expandedTopics, setExpandedTopics] = useState<Set<string>>(new Set());
  const auth = getAuth();
  const db = getFirestore();

  const getCardType = (cardId: string) => {
    switch(cardId) {
      case 'lesson_plan':
        return 'Lesson Plan';
      case 'MCQ':
        return 'Multiple Choice Questions';
      case 'assignment':
        return 'Assignment';
      default:
        return cardId;
    }
  };

  const fetchDocuments = async () => {
    if (!auth.currentUser) return;
  
    try {
      setLoading(true);
      const q = query(
        collection(db, 'documentLinks'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('createdAt', 'desc')
      );
  
      const querySnapshot = await getDocs(q);
      const documents = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          userId: data.userId,
          documentUrl: data.documentUrl,
          topic: data.topic,
          cardId: data.cardId,
          createdAt: data.createdAt?.toDate() || new Date(),
          title: data.title
        };
      });
  
      // Group documents by topic (case insensitive)
      const grouped = documents.reduce((acc: GroupedDocuments, doc) => {
        // Normalize the topic case (convert to lowercase for comparison)
        const normalizedTopic = doc.topic.toLowerCase();
        
        // Use the original topic case for display but group by normalized case
        const existingTopic = Object.keys(acc).find(key => key.toLowerCase() === normalizedTopic);
        const topicKey = existingTopic || doc.topic; // Use existing case if found, otherwise use original
  
        if (!acc[topicKey]) {
          acc[topicKey] = [];
        }
        acc[topicKey].push(doc);
        return acc;
      }, {});
  
      // Sort documents within each topic by date
      Object.keys(grouped).forEach(topic => {
        grouped[topic].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      });
  
      setDocumentLinks(grouped);
      setExpandedTopics(new Set(Object.keys(grouped)));
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [auth.currentUser]);

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  const handleCopyLink = async (documentUrl: string, docId: string) => {
    try {
      await navigator.clipboard.writeText(documentUrl);
      setCopiedId(docId);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const toggleTopic = (topic: string) => {
    const newExpanded = new Set(expandedTopics);
    if (newExpanded.has(topic)) {
      newExpanded.delete(topic);
    } else {
      newExpanded.add(topic);
    }
    setExpandedTopics(newExpanded);
  };

  if (loading) {
    return (
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Card className="login-form-card">
            <div className="loading-state">
              <div className="loader"></div>
              <p>Loading your documents...</p>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="content-header">
          <h2>My Content</h2>
          <p>Access all your educational content in one place</p>
        </div>
        
        <Card className="login-form-card documents-card">
          {Object.keys(documentLinks).length === 0 ? (
            <div className="no-documents">
              <p>No documents generated yet</p>
            </div>
          ) : (
            Object.entries(documentLinks).map(([topic, docs]) => (
              <div key={topic} className="topic-group">
                <div 
                  className="topic-header"
                  onClick={() => toggleTopic(topic)}
                >
                  <h3>{topic}</h3>
                  {expandedTopics.has(topic) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                {expandedTopics.has(topic) && (
                  <div className="documents-table">
                    <div className="table-header">
                      <div className="col-type">Type</div>
                      <div className="col-actions">Actions</div>
                      <div className="col-date">Created On</div>
                    </div>
                    <div className="table-body">
                      {docs.map((doc) => (
                        <div key={doc.id} className="table-row">
                          <div className="col-type">
                            <span className="document-type">{getCardType(doc.cardId)}</span>
                          </div>
                          <div className="col-actions">
                            <button 
                              className="action-btn copy-btn"
                              onClick={() => handleCopyLink(doc.documentUrl, doc.id)}
                              title="Copy link"
                            >
                              {copiedId === doc.id ? (
                                <span className="copied-text">Copied!</span>
                              ) : (
                                <ContentCopyIcon />
                              )}
                            </button>
                            <a
                              href={doc.documentUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="action-btn open-btn"
                              title="Open document"
                            >
                              <OpenInNewIcon />
                              <span>Open</span>
                            </a>
                          </div>
                          <div className="col-date">{formatDate(doc.createdAt)}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </Card>
      </div>
    </div>
  );
};

export default DocumentLinksList;