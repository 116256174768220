import React, { useState, useEffect } from 'react';
import { Link, BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import HeaderLogo from '../../assets/images/logo.png';
import { Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import AdditionalInfoForm, { AdditionalInfo } from '../../AdditionalInfoForm'; // Import AdditionalInfo
import { useAuth } from '../../AuthProvider';
import { Helmet } from 'react-helmet';
import OgImage from '../../assets/images/Praro-AI-og-image.png';

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [scrollToSection, setScrollToSection] = useState<string | null>(null);

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  const toggleMenu = () => {
    setMenuIsActive((prevState) => !prevState);
  };

  const handleEditInfo = async () => {
    const auth = getAuth();
    const db = getFirestore();
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data() as AdditionalInfo;
        navigate('/edit-info', { state: { additionalInfo: userData } });
      }
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={handleEditInfo}>
        Edit Info
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const scrollToSectionOnLoad = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    const headerOffset = 250;
    const extrascroll = sectionId === 'key-features' ? 400 : 0;
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY + extrascroll;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleClick = (sectionId: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (user && sectionId === 'key-features') {
      navigate('/views');
      return;
    }
    if (location.pathname !== '/') {
      setScrollToSection(sectionId);
      navigate('/');
    } else {
      scrollToSectionOnLoad(sectionId);
    }
  };

  useEffect(() => {
    if (scrollToSection && location.pathname === '/') {
      scrollToSectionOnLoad(scrollToSection);
      setScrollToSection(null);
    }
  }, [scrollToSection, location.pathname]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={`nav ${isSticky ? 'sticky' : ''}`}>
      <Helmet>
        <title>Praro AI: Teacher’s assistant for lessons, assignments, activities</title>
        <meta
          name="description"
          content="Empower your teaching with Praro today. Leverage AI to effortlessly create engaging lesson plans, assignments, quizzes and activities. Perfect for teachers and homeschooling parents. Try praro.ai today!"
        />
        <meta name="keywords" content="home, react, SEO, optimization" />
        <link rel="canonical" href="https://praro.ai" />
        <meta property="og:title" content="Praro AI: Teacher’s assistant for lessons, assignments, activities" />
        <meta
          property="og:description"
          content="Empower your teaching with Praro today. Leverage AI to effortlessly create engaging lesson plans, assignments, quizzes and activities. Perfect for teachers and homeschooling parents. Try praro.ai today!"
        />
        <meta property="og:image" content={OgImage} />
        <meta property="og:url" content="https://praro.ai" />
      </Helmet>
      <header className="header">
        <div className="container">
          <nav className="navbar">
            <a href="/" className="brand">
              <img className="img-fluid" src={HeaderLogo} alt="Logo" />
            </a>
            <div className="burger-phone-icon-section">
              <div className="user-signin-logout-section-responsive">
                {user ? (
                  <Dropdown overlay={userMenu} placement="bottomRight">
                    <Avatar
                      style={{ backgroundColor: '#044800', cursor: 'pointer' }}
                      icon={<UserOutlined />}
                    />
                  </Dropdown>
                ) : (
                  <div className="header-get-call-section">
                    <a href="/login">Sign in</a>
                  </div>
                )}
              </div>
              <button
                type="button"
                className={`burger ${menuIsActive ? 'is-active' : ''}`}
                id="burger"
                onClick={toggleMenu}
              >
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
              </button>
            </div>
            <span
              className={`overlay ${menuIsActive ? 'is-active' : ''}`}
              id="overlay"
              onClick={toggleMenu}
            ></span>
            <div className={`menu menu-section ${menuIsActive ? 'is-active' : ''}`} id="menu">
              <ul className="menu-block dropdown-for-desktop">
                <li className="menu-item">
                  <a className="menu-link" href="#home-banner" onClick={handleClick('home-banner')}>
                    Home
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link" href="#about-us" onClick={handleClick('about-us')}>
                    About us
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link" href="#key-features" onClick={handleClick('key-features')}>
                    Key Features
                  </a>
                </li>
                {user && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/my-content">
                      My Content
                    </Link>
                  </li>
                )}
                <li className="menu-item">
                  <a className="menu-link" href="#contact-us" onClick={handleClick('contact-us')}>
                    Contact
                  </a>
                </li>
              </ul>
              <div className="user-signin-logout-section-desktop">
                {user ? (
                  <Dropdown overlay={userMenu} placement="bottomRight">
                    <Avatar
                      style={{ backgroundColor: '#044800', cursor: 'pointer' }}
                      icon={<UserOutlined />}
                    />
                  </Dropdown>
                ) : (
                  <div className="header-get-call-section">
                    <a href="/login">Sign in</a>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </section>
  );
}

export default Header;
