import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Auth, onAuthStateChanged } from '@firebase/auth';

interface FormData {
  firstName: string;
  email: string;
  message: string;
}

interface ContactFormProps {
  auth: Auth;
}

function ContactForm({ auth }: ContactFormProps) {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const updateData: Partial<FormData> = {};
        
        if (user.displayName) {
          updateData.firstName = user.displayName.split(' ')[0];
        }
        
        if (user.email) {
          updateData.email = user.email;
        }

        setFormData(prev => ({
          ...prev,
          ...updateData
        }));
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await sendEmail(formData);
  };

  const sendEmail = async (data: FormData) => {
    setIsSubmitting(true);
    // Replace this URL with your Firebase function URL
    const functionUrl = 'https://sendemail-f2ck3eemba-uc.a.run.app';

    const requestPayload = {
      to: "support@praro.ai",
      from: "support@praro.ai",
      subject: `Contact Form: ${data.firstName}`,
      message: `
Name: ${data.firstName}
Email: ${data.email}

Message:
${data.message}
      `
    };

    try {
      console.log('Sending request with payload:', requestPayload);

      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestPayload)
      });

      const responseData = await response.json();
      console.log('Response:', responseData);

      if (responseData.status === 'success') {
        setFormData({
          firstName: '',
          email: '',
          message: ''
        });
        
        Swal.fire({
          icon: 'success',
          title: 'Message Sent!',
          text: 'Thank you for your message. We will get back to you soon.',
        });
      } else {
        throw new Error(responseData.message || 'Failed to send message');
      }
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error('Error sending email:', errorMessage);
      
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: `Something went wrong! Please try again or email support@praro.ai directly.`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="contact-from-label-input-section">
        <label>First Name</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-from-label-input-section">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-from-label-input-section">
        <label>Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          placeholder="How can we help you?"
          rows={5}
        ></textarea>
      </div>
      <div className="contact-us-submit-btn">
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Submit'}
        </button>
      </div>
    </form>
  );
}

export default ContactForm;