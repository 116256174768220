import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './Login';
import Views from './View';
import Detail from './Detail';
import AdditionalInfoForm, { AdditionalInfo } from './AdditionalInfoForm'; // Import AdditionalInfo
import { Layout, Menu, Typography, Dropdown, Avatar } from 'antd';
import { UserOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth } from './AuthProvider';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, setDoc, doc, getDoc } from 'firebase/firestore';
import Header from './Component/Header/Header';
import HomePage from './Template/HomePage'
import Footer from './Component/Footer/Footer';
import GenerateDetails from './GenerateDetails'; // Import the new page
import ScrollToTop from './ScrollToTop';
import MyContent from './pages/MyContent';

// const { Header, Content } = Layout;
const { Content } = Layout;
const { Title } = Typography;

const App: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBackButton = () => {
      navigate('/');
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleEditInfo = async () => {
    const auth = getAuth();
    const db = getFirestore();
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data() as AdditionalInfo;
        navigate('/edit-info', { state: { additionalInfo: userData } });
      }
    }
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={handleEditInfo}>
        Edit Info
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      
      {/* <Header style={{ backgroundColor: '#001F54', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={3} style={{ color: 'gold', margin: 0 }}>Praro.ai</Title>
        {user && (
          <Dropdown overlay={userMenu} placement="bottomRight">
            <Avatar style={{ backgroundColor: '#87d068', cursor: 'pointer' }} icon={<UserOutlined />} />
          </Dropdown>
        )}
      </Header> */}
      <Header />
      <Content className='main-wrapper'>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/generate-details" element={<GenerateDetails />} />
          <Route path="/edit-info" element={<AdditionalInfoFormWrapper />} />
          <Route path="/my-content" element={<MyContent />} />
          {user ? (
            <>
              <Route path="/views" element={<Views />} />
              <Route path="/" element={<Navigate to="/views" />} />
            </>
          ) : (
            <Route path="/" element={<Navigate to="/login" />} />
          )}
        </Routes>
        <Footer />
      </Content>
    </Layout>
  );
};

const AdditionalInfoFormWrapper: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const additionalInfo = location.state?.additionalInfo;

  const [info, setInfo] = useState<AdditionalInfo>(additionalInfo || { name: '', city: '', grade: '', subject: '', school: '' });

  const handleAdditionalInfoSubmit = async (info: AdditionalInfo) => {
    // Save the updated info to Firestore
    const auth = getAuth();
    const db = getFirestore();
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      await setDoc(userDoc, info, { merge: true });
      navigate('/views');
    }
  };

  return (
    <AdditionalInfoForm
      additionalInfo={info}
      setAdditionalInfo={setInfo}
      handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
    />
  );
};
const AppWrapper = () => (
  <>
    <ScrollToTop />
    <App />
  </>
);
export default AppWrapper;
