import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Button, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Space, Input } from 'antd';
import { ThumbUp, ThumbDown } from "@mui/icons-material";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import FeedbackForm from './FeedbackForm';
import CopyLinkButton from './CopyComponent';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
import 'katex/dist/katex.min.css';
import axios from 'axios';
import { DocumentLinksService } from './services/documentLinksService';
import { useNavigate } from 'react-router-dom';



const api_uri = process.env.REACT_APP_API_URI ?? "";

const { TextArea } = Input;

interface ResponseCardProps {
  initialResponse: string;
  topic: string;
  grade: string;
  card_id: string;
  standard: string;
  additionalDetails: string;
  isLoading: boolean;
  setResponse: React.Dispatch<React.SetStateAction<string>>;
}

interface Response {
  query: string;
  content: string;
  isComplete: boolean;
  feedback?: 'like' | 'dislike' | null;
  isExportingDrive?: boolean;
}

const ResponseCard: React.FC<ResponseCardProps> = ({
  initialResponse,
  topic,
  grade,
  card_id,
  standard,
  additionalDetails,
  isLoading,
  setResponse
}) => {
  const db = getFirestore();
  const auth = getAuth();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportLink, setExportLink] = useState('');
  const [loading, setLoading] = useState(isLoading);
  const [refineInput, setRefineInput] = useState('');
  const [responses, setResponses] = useState<Response[]>([]);
  const [showRefinementInput, setShowRefinementInput] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);
  const navigate = useNavigate();  // Add this line at the top of your component


  const log = useCallback((message: string, data?: any) => {
    console.log(`[ResponseCard] ${message}`, data);
  }, []);

  useEffect(() => {
    log('Component mounted');
    return () => log('Component will unmount');
  }, [log]);

  useEffect(() => {
    setLoading(isLoading);
    log('isLoading changed', isLoading);
  }, [isLoading, log]);

  useEffect(() => {
    if (initialResponse) {
      log('Setting initial response', initialResponse);
      setResponses([{
        query: 'Initial Query',
        content: initialResponse,
        isComplete: true,
        isExportingDrive: false
      }]);
    }
  }, [initialResponse, log]);

  useEffect(() => {
    log('Responses updated', responses);
    setShowRefinementInput(responses.some(res => res.isComplete));
  }, [responses, log]);

  const handleExportGoogleDrive = async (text: string, index: number) => {
    log('Exporting to Google Drive');
    let user = auth?.currentUser;
  
    setResponses(prev => {
      const newResponses = [...prev];
      newResponses[index].isExportingDrive = true;
      return newResponses;
    });
  
    try {
      if (user) {
        const serverResponse = await axios.post(`${api_uri}/create-doc`, {
          firebase_user_id: user.uid,
          markdown_text: text,
          card_id: card_id,
          topic: topic
        });
  
        const { document_url } = serverResponse.data;
        setExportLink(document_url);
        
        // Save the document link
        const documentLinksService = new DocumentLinksService();
        await documentLinksService.saveDocumentLink({
          userId: user.uid,
          documentUrl: document_url,
          topic: topic,
          cardId: card_id,
          title: `Generated content for ${topic}`
        });
  
        setExportDialogOpen(true);
      }
    } catch (error) {
      console.error("Error during Google Drive export:", error);
    } finally {
      setResponses(prev => {
        const newResponses = [...prev];
        newResponses[index].isExportingDrive = false;
        return newResponses;
      });
    }
  };

  const handleFeedbackOpen = () => {
    log('Opening feedback dialog');
    if (auth.currentUser) {
      setFeedbackOpen(true);
    } else {
      console.error("User not authenticated");
    }
  };

  const handleFeedbackClose = () => {
    log('Closing feedback dialog');
    setFeedbackOpen(false);
  };

  const handleExportClose = () => {
    log('Closing export dialog');
    setExportDialogOpen(false);
  };

  const saveSignal = async (event: React.MouseEvent, signalType: 'like' | 'dislike', index: number) => {
    event.preventDefault();
    log(`Saving signal: ${signalType} for response at index ${index}`);

    setResponses(prevResponses => {
      const newResponses = [...prevResponses];
      newResponses[index].feedback = signalType === newResponses[index].feedback ? null : signalType;
      return newResponses;
    });
  };

  const handleRefineSubmit = () => {
    log('Refine submit triggered', refineInput);
    if (!refineInput) {
      console.error('Refine input is required');
      return;
    }

    setLoading(true);
    setShowRefinementInput(false);

    const wsUri = process.env.REACT_APP_WS_URI || '';
    if (!wsUri) {
      console.error('WebSocket URI is not configured.');
      setLoading(false);
      setShowRefinementInput(true);
      return;
    }

    const websocket = new WebSocket(wsUri);
    websocketRef.current = websocket;

    setResponses(prev => {
      const newResponses = [...prev, {
        query: refineInput,
        content: '',
        isComplete: false,
        isExportingDrive: false
      }];
      log('Added new response to responses array', newResponses);
      return newResponses;
    });

    websocket.onopen = () => {
      log('WebSocket opened');
      const message = JSON.stringify({
        topic,
        grade,
        card_id,
        standard,
        refineInput,
      });

      websocket.send(message);
    };

    websocket.onmessage = (event) => {
      log('WebSocket message received', event.data);
      const data = event.data.replaceAll('^^^', '\n');
      setResponses(prev => {
        const newResponses = [...prev];
        const lastResponse = newResponses[newResponses.length - 1];
        lastResponse.content += data;
        log('Updated response content', newResponses);
        return newResponses;
      });
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error occurred:', error);
      setLoading(false);
      setShowRefinementInput(true);
    };

    websocket.onclose = () => {
      log('WebSocket closed');
      setResponses(prev => {
        const newResponses = [...prev];
        const lastResponse = newResponses[newResponses.length - 1];
        lastResponse.isComplete = true;
        log('Marked last response as complete', newResponses);
        return newResponses;
      });
      setLoading(false);
      setRefineInput('');
      setShowRefinementInput(true);
    };
  };

  const renderResponseCard = (res: Response, index: number) => {
    log(`Rendering response card for index ${index}`, res);
    return (
      <Card key={index} className="markdown-container" style={{ width: '100%', marginTop: '20px', padding: '0px', background: 'none' }}>
        <div className="query-text-export-btn-section">
          <div className="query-text-section">
            <h4 className='query-text'>Query: {res.query}</h4>
          </div>
          <div className="export-text-export-drive-btn">
            <Button
              onClick={() => handleExportGoogleDrive(res.content, index)}
              disabled={!res.isComplete || loading || res.isExportingDrive}
            >
              {res.isExportingDrive ? 'Exporting...' : 'Export to Google Drive'}
            </Button>
          </div>
        </div>
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
        >
          {res.content}
        </ReactMarkdown>
        {res.isComplete && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div>
              <IconButton
                onClick={(event) => saveSignal(event, 'like', index)}
                aria-label="like"
                style={{
                  backgroundColor: res.feedback === 'like' ? '#e6f7ff' : 'transparent',
                  color: res.feedback === 'like' ? '#1890ff' : 'inherit'
                }}
              >
                <ThumbUp />
              </IconButton>
              <IconButton
                onClick={(event) => saveSignal(event, 'dislike', index)}
                aria-label="dislike"
                style={{
                  backgroundColor: res.feedback === 'dislike' ? '#fff1f0' : 'transparent',
                  color: res.feedback === 'dislike' ? '#ff4d4f' : 'inherit'
                }}
              >
                <ThumbDown />
              </IconButton>
            </div>
          </div>
        )}
      </Card>
    );
  };

  return (
    <Space direction="vertical" style={{ marginTop: 20, width: '100%' }}>
      {responses.map((res, index) => renderResponseCard(res, index))}

      {showRefinementInput && !loading && (
        <>
          <div className="login-form-input-section">
            <TextArea
              className='login-form-input'
              value={refineInput}
              onChange={(e) => setRefineInput(e.target.value)}
              placeholder="Enter any refinements or further details for the response"
              rows={1}
              style={{ marginTop: 20, width: '100%' }}
            />
          </div>
          <div className="ask-praro-feedback-btn">
            <button className="feedback-button" onClick={handleFeedbackOpen}>Give Feedback</button>
            <Button
              className='ask-praro-btn'
              variant="contained"
              onClick={handleRefineSubmit}
            >
              Ask Praro &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
            </Button>
          </div>
        </>
      )}

<Dialog open={exportDialogOpen} onClose={handleExportClose} fullWidth maxWidth="sm">
  <div className="dialog-cancel-btn">
    <button onClick={handleExportClose}><i className="fa-solid fa-circle-xmark"></i></button>
  </div>
  <DialogTitle>
    <div className="dialog-title-section">
      <div className="success-icon">
        <i className="fa-solid fa-check-circle" style={{ color: '#044800', fontSize: '48px' }}></i>
      </div>
      <h2 className="dialog-title">Content Generated Successfully!</h2>
    </div>
  </DialogTitle>
  <DialogContent>
    <div className="export-dialog-content">
      <div className="document-link-section">
        <p className="link-label">Document Link:</p>
        <div className="link-container">
          <a 
            href={exportLink} 
            target="_blank" 
            rel="noopener noreferrer"
            className="document-link"
          >
            {exportLink}
          </a>
          <CopyLinkButton link={exportLink} />
        </div>
      </div>
      
      <div className="access-info-section">
        <p>You can find all your generated documents in the My Content section.</p>
        
        <div className="dialog-actions">
          <Button
            onClick={handleExportClose}
            variant="outlined"
            className="dialog-button dismiss-button"
            style={{
              borderColor: '#044800',
              color: '#044800'
            }}
          >
            Continue Editing
          </Button>
          <Button
            onClick={() => {
              handleExportClose();
              navigate('/my-content');
            }}
            variant="contained"
            className="dialog-button view-content-button"
            style={{
              backgroundColor: '#044800',
              color: 'white'
            }}
          >
            View in My Content
          </Button>
        </div>
      </div>
    </div>
  </DialogContent>
</Dialog>

      <Dialog open={feedbackOpen} onClose={handleFeedbackClose} fullWidth maxWidth="sm">
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <FeedbackForm userTopic={topic} userGrade={grade} onClose={handleFeedbackClose} auth={auth} />
        </DialogContent>
      </Dialog>
    </Space>
  );
};

export default ResponseCard;